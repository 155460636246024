// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--rcns1";
export var box__jjb66 = "PlasmicPricing-module--box__jjb66--20x4q";
export var header = "PlasmicPricing-module--header--arUMc";
export var section__vgsil = "PlasmicPricing-module--section__vgsil--2lyvC";
export var box__u6UdK = "PlasmicPricing-module--box__u6UdK--k8FFS";
export var plan__wo2Vh = "PlasmicPricing-module--plan__wo2Vh--1MUU0";
export var box__hd65L = "PlasmicPricing-module--box__hd65L--KmFqy";
export var box__goSEd = "PlasmicPricing-module--box__goSEd--GSXYZ";
export var bullet___9ZvDm = "PlasmicPricing-module--bullet___9ZvDm--2czWV";
export var bullet__sPqeA = "PlasmicPricing-module--bullet__sPqeA--j9CBD";
export var bullet__rtfP2 = "PlasmicPricing-module--bullet__rtfP2--17CF4";
export var plan__wsqEz = "PlasmicPricing-module--plan__wsqEz--P5jgF";
export var box__sJaad = "PlasmicPricing-module--box__sJaad--3NFDb";
export var box__rYxki = "PlasmicPricing-module--box__rYxki--2oKBn";
export var bullet__nzChu = "PlasmicPricing-module--bullet__nzChu--9V9gR";
export var bullet__wlnVw = "PlasmicPricing-module--bullet__wlnVw--3BbP2";
export var bullet___1BVsU = "PlasmicPricing-module--bullet___1BVsU--24mxv";
export var bullet__yicqP = "PlasmicPricing-module--bullet__yicqP--2pR7S";
export var plan__em1Gm = "PlasmicPricing-module--plan__em1Gm--1SzvB";
export var box__jeKMu = "PlasmicPricing-module--box__jeKMu--2WdgK";
export var box__bUvT7 = "PlasmicPricing-module--box__bUvT7--3q4L5";
export var bullet__fTQpT = "PlasmicPricing-module--bullet__fTQpT--2Cgzr";
export var bullet__mqQwt = "PlasmicPricing-module--bullet__mqQwt--KiAsO";
export var bullet__gtCx = "PlasmicPricing-module--bullet__gtCx---zoas";
export var section___5IX = "PlasmicPricing-module--section___5IX--3oY7-";
export var faq__qqG4 = "PlasmicPricing-module--faq__qqG4--1rkrD";
export var faq__ti8Z = "PlasmicPricing-module--faq__ti8Z--1gkzh";
export var faq__avXvj = "PlasmicPricing-module--faq__avXvj--2_Bqw";
export var footer = "PlasmicPricing-module--footer--k54-O";
// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--27EcQ";
export var box__wDEut = "PlasmicPlan-module--box__wDEut--28xmG";
export var box__vHwc0 = "PlasmicPlan-module--box__vHwc0--1onu6";
export var slotName = "PlasmicPlan-module--slotName--1clZ5";
export var box__fiwbS = "PlasmicPlan-module--box__fiwbS--3HO5k";
export var box__lll6D = "PlasmicPlan-module--box__lll6D--3kzdb";
export var bullet__wqpcK = "PlasmicPlan-module--bullet__wqpcK--3ucmP";
export var bullet__vrzaA = "PlasmicPlan-module--bullet__vrzaA--3gsd_";
export var bullet__om8GJ = "PlasmicPlan-module--bullet__om8GJ--2doPx";
export var box___30Yuy = "PlasmicPlan-module--box___30Yuy--3b3LS";
export var svg__vvOaf = "PlasmicPlan-module--svg__vvOaf--j2dOS";